<template>
  <div>
    <div class="search">
      <!-- <div>
                <div class="a-inline-block">
                    <label for="">关键字</label><a-input v-model="key" size="large" placeholder="请输入编号或分类名称" />
                </div>
                <div class="a-inline-block">
                    <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                    <a-button size="large" @click="reset">重置</a-button>
                </div>
            </div> -->
      <a-button class="addBtn" size="large" type="primary" @click="showAdd">添加</a-button>
      <div style="text-align:right;">
        <a-button class="addBtn" size="large" type="primary" @click="delAll">批量删除</a-button>
      </div>
    </div>
    <a-table :row-selection="rowSelection" :rowKey="record=> record.id" :columns="columns" :data-source="data"
      class="table" :pagination="pagination">
      <span slot="num" slot-scope="text, record, index">
        {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
      </span>
      <template slot="Action" slot-scope="text,record">
        <a class="action" href="javascript:;" @click="editType(record.id,record)">编辑</a>
        <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
          <a class="action del" href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal v-model="visible" title="添加技能" @ok="addType">
      <div class="addBox">
        <div class="add_title">请输入技能名称</div>
        <a-input class="add_inp" style="margin-bottom:15px" v-model="addName" />
        <div class="add_title">请输入技能日薪资</div>
        <a-input class="add_inp" v-model="addSalary" />
      </div>
    </a-modal>
  </div>
</template>
<script>
// import $ from "jquery";
import { requestXml, getDateTime } from "../../../assets/js/request";
export default {
  data() {
    return {
      key: "",
      type: null,
      status: null,
      visible: false,
      onID: "", //编辑状态id
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          width: 70,
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        // { title: '编号', dataIndex: 'id', key: '1'},
        { title: "分类名称", align: "center", dataIndex: "name", key: "2" },
        { title: "技能日薪资", align: "center", dataIndex: "salary", key: "4" },
        { title: "添加时间", align: "center", dataIndex: "created", key: "3" },
        {
          title: "操作",
          key: "operation",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "Action" },
        },
      ],
      data: [],
      addName: "", //添加分类名称
      addSalary: "", //添加薪资
      // 分页
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        showTotal: total => `共 ${total} 条`, // 显示总数
        onChange: (current) => this.changePage(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
      selectedRows: "",
      rowSelection: {
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
          this.selectedRows = selectedRows;
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
          this.selectedRows = selectedRows;
        },
      },
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    // 搜索
    onSearch() {
      this.getData(this.key, this.type);
    },
    reset() {
      this.key = "";
      this.type = null;
      this.status = null;
    },
    getData() {
      requestXml(
        "/jieb/Temporary/skillshow",
        "POST",
        (res) => {
          for (let i = 0; i < res.list.length; i++) {
            res.list[i].created = getDateTime(res.list[i].created);
          }
          this.data = res.list;
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
        },
        {
          page: {
            curpage: this.pagination.current,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    // 分页
    changePage(current) {
      //current参数表示是点击当前的页码，
      console.log(current);
      this.pagination.current = current;
      this.getData(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData(); //向后端发送请求
    },
    //添加分类
    showAdd() {
      this.visible = true;
    },
    addType() {
      // console.log(this.addText);
      if (this.addName != "") {
        let url = "/jieb/Temporary/skilladd";
        let data = { name: this.addName, salary: this.addSalary };
        if (this.onID) {
          url = "/jieb/Temporary/skilledit";
          data.id = this.onID;
        }
        requestXml(
          url,
          "POST",
          (res) => {
            console.log(res);
            this.visible = false;
            this.onID = "";
            this.addName = "";
            this.addSalary = "";
            this.getData();
          },
          data
        );
      }
    },
    // 编辑
    editType(id, res) {
      this.onID = id;
      this.addName = res.name;
      this.addSalary = res.salary;
      this.visible = true;
    },
    // 删除
    onDelete(id) {
      requestXml(
        "/jieb/Temporary/skilldel",
        "POST",
        (res) => {
          console.log(res);
          this.getData();
        },
        { id: id }
      );
    },
    delAll() {
      if (this.selectedRows.length < 1) {
        this.$message.info("请先选择");
        return false;
      } else {
        let idArr = [];
        let sel = this.selectedRows;
        for (let i = 0; i < sel.length; i++) {
          idArr.push(sel[i].id);
        }
        this.onDelete(idArr);
      }
    },
  },
};
</script>
<style scoped>
.search {
  display: flex;
  justify-content: space-between;
}
.addBtn {
  width: auto;
}
.action {
  display: inline-block;
  padding: 5px 10px;
  margin: 0 5px;
  background: #dceeff;
  border: 1px solid #36a3ff;
  color: #36a3ff;
  border-radius: 5px;
}
.del {
  background: #ffdcdc;
  border-color: #ff4c4c;
  color: #ff4c4c;
}
.add_title {
  padding: 15px 0;
}
.add_inp {
  height: 40px;
  margin-bottom: 60px;
}
</style>

